<template>
  <div
    class="sp-product-item-wrapper fs-13 fc-555 fb-500"
    :data-best="isIndexBest"
    :data-new="isNewArrivals"
    :data-name="route.name"
  >
    <a
      class="product-img-wrapper position-relative"
      :href="`/product/${productInfo.code}_${$Utils.replaceSpacesWithDash(
        productInfo.nameEn
      )}${getRouteQuery()}`"
      @click="goDetail(productInfo)"
    >
      <!--  :lazy-src="$multiPlatform.lazyimgplaceholder" -->
      <v-img
        class="w-100 mb-2 img"
        :aspect-ratio="1"
        v-if="productInfo.imgMainUrl"
        :src="productInfo.imgMainUrl + '?x-oss-process=image/resize,l_300'"
      ></v-img>
      <!-- <p class="clearance-tag" v-if="showTag && productInfo.clearanceFlag">
        Clearnce
      </p> -->
    </a>
    <a
      class="pointer mt-4"
      :href="`/product/${productInfo.code}_${$Utils.replaceSpacesWithDash(
        productInfo.nameEn
      )}?t=${route.query.keyword ? 's' : ''}${
        route.query.keyword ? '&k=' + route.query.keyword : ''
      }`"
      @click="goDetail(productInfo)"
    >
      <p v-bind="props" class="fc-555 fs-14 name" :title="productInfo.nameEn">
        {{ replaceName(productInfo.nameEn) }}
      </p>
    </a>
    <p class="fb-400 my-1"># {{ productInfo.code }}</p>
    <div class="price">
      <span>Low As&nbsp;&nbsp;</span>
      <span class="fs-16 fb-650 fc-black-09">${{ productInfo.price }}</span>
    </div>
    <div v-if="showTag" class="product-tags">
      <img
        v-if="productInfo.calcFreight"
        :width="86"
        aspect-ratio="16/9"
        cover
        :src="freeShipping"
        class="mt-1"
      />
      <img
        v-if="productInfo.freeSampleFlag"
        :width="86"
        aspect-ratio="16/9"
        cover
        :src="freeSample"
        class="mt-1"
      />
    </div>
  </div>
</template>

<script setup>
import freeShipping from "~/assets/svg/freeShipping.svg";
import freeSample from "~/assets/svg/freeSample.svg";
const { $Utils, $multiPlatform } = useNuxtApp();
const config = useRuntimeConfig();
const primaryColor = config.public.primaryColor;
// 定义props productInfo，类型是对象，默认值是{}
const props = defineProps({
  productInfo: {
    type: Object,
    required: true
  },
  showTag: {
    type: Boolean
  },
  isIndexBest: {
    //是否是首页best sellers
    type: Boolean,
    default: false
  },

  isNewArrivals: {
    //是否是首页New Arrivals
    type: Boolean,
    default: false
  }
});

const route = useRoute();
const excludesRoutes = ["product-id", "my-cart"];

const getRouteQuery = () => {
  if (excludesRoutes.includes(route.name)) {
    return "";
  }
  if (route?.query?.keyword) {
    return `?t=s&k=${route.query.keyword}`;
  } else if (route.query.t) {
    return `?t=${route.query.t}`;
  }
  if (props.isIndexBest) {
    return `?t=be`;
  }

  if (props.isNewArrivals) {
    return `?t=ne`;
  }
  return "";
};

const replaceName = str => {
  return str.replace(/\s+/g, " ");
};
/**
 * 跳转到产品详情
 * @param {info} 产品数据
 */

const emit = defineEmits(["shopmall-product-item-click"]);

const { $Buryingpoint } = useNuxtApp();

const goDetail = info => {
  emit("shopmall-product-item-click", info);
  if (props.isIndexBest) {
    // bestSellers产品点击

    return $Buryingpoint.bestClick(info);
  }

  if (props.isNewArrivals) {
    // New Arrivals 产品点击
    return $Buryingpoint.newClick(info);
  }
};
</script>

<style lang="scss" scoped>
.sp-product-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .name {
    width: 100%;
    height: 30px;
    line-height: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
  .product-img-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ededed;
    border-radius: 4px;
    .clearance-tag {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 4px 8px;
      border: 1px solid #ff3c3c;
      color: #ff3c3c;
      font-size: 12px;
      line-height: 12px;
      border-radius: 3px;
      background-color: #fff;
    }
    &:hover {
      transform: scale(1.035);
      border-color: v-bind(primaryColor);
    }
    img {
      position: absolute;
      border-radius: 4px;
      top: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      transform: translateY(-50%);
    }
    .img {
      position: absolute;
      border-radius: 4px;
      top: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      transform: translate3d(0, -50%, 0);
    }
  }
  .product-tags {
    display: flex;
    img {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

a:hover {
  text-decoration: underline;
  color: black;
}

p {
  font-size: 14px;
  color: #999;
}
.price {
  line-height: 1.2;
}
:deep(.v-img__img--preload) {
  filter: none;
}
</style>
